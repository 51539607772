<template>
  <div class="appGoalsProgressBar">
    <div class="appGoalsProgressBar__total">
      <div
        class="appGoalsProgressBar__total__completed"
        :style="{
          width: progressWidth,
          background: bgProgressColor,
          'max-width': '100%',
        }"
      />
      <div class="appGoalsProgressBar__text">
        <!-- {{ text }} -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      // type: Number,
      // default: 0
    },
    text: {
      type: String,
      default: "",
    },
    bgProgressColor: {
      type: String,
      default: "",
    },
    progressValue: {
      // type: Number,
      // default: 0
    },
  },
  computed: {
    progressWidth() {
      return this.progressValue + "%";
    },
  },
};
</script>

<style lang="scss">
.appGoalsProgressBar {
  width: 90%;
  height: 15px;
  background-color: #ece9e6;
  border-radius: 50px;
  overflow: hidden;
  // margin-left: 5px;
  margin: 0 auto 10px auto;
  line-height: 35px;
  &__total {
    height: 100%;
    // background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50px;

    &__completed {
      height: 100%;
      // background: #FF4F79;
      // border-radius: 50px;
      // border-top-right-radius: 0px;
      // border-bottom-right-radius: 0px;
    }
  }

  &__text {
    width: 100%;
    margin-top: -30px;
    font-size: 16px;
    // line-height: 21px;
    color: #ffffff;
    // display: none;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.05);
  }
}

@media screen and (max-width: 600px) {
  .appGoalsProgressBar {
    width: 100%;
  }
}
</style>
