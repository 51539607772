<template>
  <div class="list-box">
    <img
      class="list-img"
      :src="resolveImage(defaultPicture)"
    >
    <div class="list-content">
      <p class="title">
        {{ name }}
      </p>
      <p class="tips">
        {{ description }}
      </p>
      <p class="time">
        <small>&pound;</small>{{ formatPrice(price) }}
      </p>
      <div
        style="font-size: 12px; color: var(--main)"
        :style="[type == 'goal' ? { 'margin-bottom': '5px' } : {}]"
      >
        <small>{{ getProgressBarText() }}</small>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <AppProductProgressBar
          :value="progressValue"
          :text="getProgressBarText()"
          :bg-progress-color="
            progressValue == 100
              ? 'linear-gradient(90deg, #56AB2F 0%, #A8E063 100%)'
              : 'linear-gradient(90deg, #FC4A1A 0%, #F7B733 100%)'
          "
          :progress-value="progressValue"
          style="width: 85%; margin: 0; height: 6px"
        />
        <img
          v-if="occasion"
          style="width: 15px; height: 15px"
          :src="resolveImage(occasion)"
        >
      </div>
    </div>
  </div>
</template>

<script>
  import AppProductProgressBar from "../components/AppProductProgressBar";
  // import AppModal from "@/components/AppModal";
  // import AppWishlistProductDetails from "@/components/AppWishlistProductDetails";
  // import AppButton from "@/components/AppButton";
  import { mapGetters, mapActions } from "vuex";
  import config from "../config";

  export default {
    components: {
      AppProductProgressBar,
      // AppModal,
      // AppWishlistProductDetails,
      // AppButton,
    },
    props: {
      type: {
        type: String,
        default: "",
      },
      name: {
        type: String,
        default: "",
      },
      description: {
        type: String,
        default: "",
      },
      price: {
        // type: Number,
        // default: 0
      },
      progressValue: {
        // type: Number,
        // default: 0
      },
      stars: {
        // type: Number,
        // default: 0
      },
      savingsAdded: {
        // type: String,
        // default: ''
      },
      cashYouMoreNeed: {
        // type: Number,
        // default: 0
      },
      goalAccountId: {
        // type: Number,
        // default: 0
      },
      defaultPicture: {
        type: String,
        default: "",
      },
      images: {
        type: Array,
        default: () => [],
      },
      productId: {
        type: Number,
        default: 0,
      },
      givenStars: {
        type: Number,
        default: 0,
      },
      wishlistId: {
        type: Number,
        default: 0,
      },
      occasion: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        goalModal: false,
        deleteWishlistItemModal: false,
        sheetProductDetails: false,
      };
    },
    computed: {
      ...mapGetters(["getSpendWalletBalance"]),
    },
    methods: {
      ...mapActions(["deleteWishlist"]),
      resolveImage(src) {
        return config.HOST + src;
      },
      openGoalDetailsModal() {
        this.goalModal = true;
      },
      closeGoalDetailsModal() {
        this.goalModal = false;
        this.getSpendWalletBalance;
      },
      getProgressBarText() {
        if (this.progressValue == 100) return "You can buy this";
        else
          return "You need £" + Number(this.cashYouMoreNeed.toFixed(2)) + " more";
      },
      openDeleteWishlistItemModal() {
        this.deleteWishlistItemModal = true;
      },
      closeDeleteWishlistItemModal() {
        this.deleteWishlistItemModal = false;
      },
      deleteWishlistItemMethod() {
        if (this.type == "wishlist") {
          this.deleteWishlist(this.wishlistId);
        }
        this.closeDeleteWishlistItemModal();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .list_ul {
    list-style: none;
    overflow: hidden;
  }

  .list-item {
    position: relative;
    height: 4rem;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  .list-item[data-type="0"] {
    transform: translate3d(0, 0, 0);
  }

  .list-item[data-type="1"] {
    transform: translate3d(-5rem, 0, 0);
  }

  .list-box {
    padding: 0.5rem;
    background: #fff;
    display: flex;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    justify-content: flex-end;
    border: 1px solid #dedede;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);
  }

  .list-item .list-img {
    display: block;
    width: 3.5rem;
    height: 3.5rem;
    object-fit: contain;
  }

  .list-item .list-content {
    padding: 0.25rem 0 0.25rem 0.5rem;
    position: relative;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
  }

  .list-item .title {
    display: block;
    overflow: hidden;
    font-size: 0.75rem;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 165px;
  }

  .list-item .tips {
    display: block;
    overflow: hidden;
    font-size: 0.6rem;
    color: #999;
    line-height: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
  }

  .list-item .time {
    display: block;
    font-size: 0.8rem;
    position: absolute;
    right: 0;
    top: 0;
    color: var(--main);
    font-weight: 700;
  }

  .list-item .delete {
    width: 5rem;
    height: 4rem;
    background: #ff4949;
    font-size: 0.85rem;
    color: #fff;
    text-align: center;
    line-height: 4rem;
    position: absolute;
    top: 0;
    right: -5rem;
  }
</style>
