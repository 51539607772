<template>
  <div>
    <header>
      <div
        style="
          text-align: center;
          color: var(--main);
          line-height: 28px;
          padding: 40px 40px 20px 40px;
        "
      >
        <h1 style="font-size: 25px; font-weight: 800">
          Wishlist
        </h1>
        <p style="font-size: 12px; font-weight: 600">
          Your wishlist items by categories
        </p>
      </div>
    </header>
    <div
      class="missionDays"
      style="padding: 20px 0 20px 10px; background: #ffffff"
    >
      <AppOccasionItem
        v-for="(occasion, index) in getOccasions"
        :key="index"
        :name="occasion.name"
        :image="occasion.image_link"
        :is-active="occasion.id == $store.state.selectedWishlistOccasionId"
        @click.native="selectCategory(occasion.id)"
        @touchstart.capture="touchStart"
        @touchend.capture="touchEnd"
      />
    </div>

    <div style="padding: 0 10px; margin-bottom: 30px">
      <div
        class="searchBar border-2 border-[#dedede]"
        style="border-radius: 100px"
      >
        <input
          v-model="search"
          style="padding-top: 10px; padding-bottom: 10px"
          placeholder="Search..."
        >
        <svg
          version="1.1"
          class="fa-search"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="20"
          height="20"
          viewBox="0 0 124.524 124.524"
          style="enable-background: new 0 0 124.524 124.524"
          xml:space="preserve"
        >
          <g>
            <path
              fill="#7a615a59"
              d="M51,102.05c10.5,0,20.2-3.2,28.3-8.6l29.3,29.3c2.301,2.3,6.101,2.3,8.5,0l5.7-5.7c2.3-2.3,2.3-6.1,0-8.5L93.4,79.35
		c5.399-8.1,8.6-17.8,8.6-28.3c0-28.1-22.9-51-51-51c-28.1,0-51,22.9-51,51C0,79.149,22.8,102.05,51,102.05z M51,20.05
		c17.1,0,31,13.9,31,31c0,17.1-13.9,31-31,31c-17.1,0-31-13.9-31-31C20,33.95,33.9,20.05,51,20.05z"
            />
          </g>
        </svg>
        <!-- <div class="spinner"><i class="fa fa-spinner"></i></div> -->
      </div>
    </div>

    <div
      v-if="resultQuery.length > 0"
      style="padding: 0 10px"
    >
      <ul
        class="list_ul"
        style="padding: 0 10px"
      >
        <li
          v-for="(wishlist, index) in resultQuery"
          :key="index"
          class="list-item"
          data-type="0"
        >
          <div
            @touchstart.capture="touchStart"
            @touchend.capture="touchEnd"
            @click="skip(wishlist)"
          >
            <MobileWishlistItemNew
              type="wishlist"
              :product-id="wishlist.products.id"
              :wishlist-id="wishlist.id"
              :name="wishlist.products.name"
              :price="Number(wishlist.products.price).toFixed(2)"
              :default-picture="wishlist.products.default_picture_link"
              :images="getProductImages(wishlist.products)"
              :progress-value="Number(wishlist.percent).toFixed(2)"
              :description="wishlist.products.description"
              :occasion="wishlist.occasion.image_link"
              :cash-you-more-need="wishlist.cash_u_need_more"
              :stars="Number(wishlist.products.stars)"
            />
          </div>
          <div
            class="delete"
            :data-index="index"
            @click="deleteItem(wishlist.id)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style="width: 25px; height: 25px"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </div>
        </li>
      </ul>
    </div>
    <div
      v-else
      class="recent_container"
      style="padding: 0 20px"
    >
      <div
        class="shadow"
        style="
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 90px;
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          background: white;
          padding: 15px;
          border-radius: 15px;
          position: relative;
          overflow: hidden;
        "
      >
        <div
          style="
            text-align: center;
            color: var(--secondary);
            font-size: 12px;
            font-weight: 500;
          "
        >
          No items found
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AppOccasionItem from "../../components/AppOccasionItem";
  import MobileWishlistItemNew from "../../components/MobileWishlistItemNew";
  import { mapActions, mapGetters } from "vuex";
  import _ from "lodash";

  (function (doc, win) {
    var docEl = doc.documentElement,
      resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
      recalc = function () {
        var clientWidth = docEl.clientWidth;
        if (!clientWidth) return;
        docEl.style.fontSize = 20 * (clientWidth / 375) + "px";
      };
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener("DOMContentLoaded", recalc, false);
  })(document, window);

  export default {
    components: {
      AppOccasionItem,
      MobileWishlistItemNew,
    },
    data() {
      return {
        section: "shop",
        search: null,
        selectedCategoryProductsIndex: 0,
        all_products: [],
        selectedProduct: [],
        selectedProductCurrentIndex: 0,
        transferModal: false,
        startX: 0,
        endX: 0,
      };
    },
    computed: {
      ...mapGetters([
        "getOccasions",
        "getSpendWalletBalance",
        "getSaveWalletBalance",
        "getWishlist",
      ]),
      resultQuery() {
        if (this.search) {
          return this.getWishlist.filter((item) => {
            return this.search
              .toLowerCase()
              .split(" ")
              .every((v) => item.products.name.toLowerCase().includes(v));
          });
        } else {
          return this.getWishlist;
        }
      },
    },
    created() {
      const user = {
        userId: localStorage.getItem("children_user_id"),
        token: localStorage.getItem("children_token"),
        family_id_check: localStorage.getItem("children_family_id"),
      };
      this.fetchProfile();
      this.setupUser(user);
      this.fetchOccasions(),
        (this.searchInWishlist = _.debounce(this.searchInWishlist, 1000)),
        this.fetchSpendWalletAndGoalsBalance(),
        this.fetchWishlist(null);
    },
    methods: {
      ...mapActions([
        "fetchOccasions",
        "fetchSpendWalletAndGoalsBalance",
        "fetchWishlist",
        "setupUser",
        "fetchProfile",
        "deleteWishlist",
      ]),
      //Jump
      skip: function (wishlist) {
        if (this.checkSlide()) {
          this.restSlide();
        } else {
          const productObj = {
            from: "wishlist",
            selectedSection: "details",
            name: wishlist.products.name,
            productId: wishlist.products.id,
            description: wishlist.products.description,
            price: Number(wishlist.products.price).toFixed(2),
            stars: Number(wishlist.products.stars),
            defaultPicture: wishlist.products.default_picture_link,
            images: this.getProductImages(wishlist.products),
            cashYouMoreNeed: wishlist.cash_u_need_more,
          };

          localStorage.setItem("storeProduct", JSON.stringify(productObj));
          this.$router.replace({
            name: "children-product_details_wishlist",
            // params: {
            //   wish: {
            //     selectedSection: "details",
            //     name: wishlist.products.name,
            //     productId: wishlist.products.id,
            //     description: wishlist.products.description,
            //     price: Number(wishlist.products.price).toFixed(2),
            //     stars: Number(wishlist.products.stars),
            //     defaultPicture: wishlist.products.default_picture_link,
            //     images: this.getProductImages(wishlist.products),
            //     cashYouMoreNeed: wishlist.cash_u_need_more,
            //   },
            // },
          });
        }
      },
      //Start sliding
      touchStart: function (e) {
        // record the initial position
        this.startX = e.touches[0].clientX;
      },
      //End of sliding
      touchEnd: function (e) {
        // The parent element of the current slide
        let parentElement = e.currentTarget.parentElement;
        // record end position
        this.endX = e.changedTouches[0].clientX;
        // slide left
        if (parentElement.dataset.type == 0 && this.startX - this.endX > 30) {
          this.restSlide();
          parentElement.dataset.type = 1;
        }
        // slide right
        if (parentElement.dataset.type == 1 && this.startX - this.endX < -30) {
          this.restSlide();
          parentElement.dataset.type = 0;
        }
        this.startX = 0;
        this.endX = 0;
      },
      //Determine whether there is a slider in the sliding state
      checkSlide: function () {
        let listItems = document.querySelectorAll(".list-item");
        for (let i = 0; i < listItems.length; i++) {
          if (listItems[i].dataset.type == 1) {
            return true;
          }
        }
        return false;
      },
      //Reset the sliding state
      restSlide: function () {
        let listItems = document.querySelectorAll(".list-item");
        // reset
        for (let i = 0; i < listItems.length; i++) {
          listItems[i].dataset.type = 0;
        }
      },
      //Delete
      deleteItem: function (e) {
        var x = confirm("Are you sure you want to delete it?");
        if (x) {
          this.deleteWishlist(e);
          this.restSlide();
        } else return false;
      },
      openTranferModal() {
        this.transferModal = true;
      },
      closeTranferModal() {
        this.transferModal = false;
      },
      getProductImages(product) {
        if (product == null) return [];
        var pictures = [];
        if (product.default_picture_link)
          pictures[0] = product.default_picture_link;
        if (product.picture1_link) pictures[1] = product.picture1_link;
        if (product.picture2_link) pictures[2] = product.picture2_link;
        if (product.picture1_link) pictures[3] = product.picture2_link;
        return pictures;
      },
      searchInWishlist(value) {
        if (value.length > 2) {
          this.fetchWishlist(value);
        } else {
          this.fetchWishlist(null);
        }
      },
      selectCategory(occasionId) {
        this.search = "";
        this.$store.state.selectedWishlistOccasionId = occasionId;
        this.fetchWishlist(null);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .searchBar {
    background-color: #fff;
    width: 100%;
    // border: 1px solid #e0e0e0;
    border-radius: 10px;
    outline: none;
    font-size: 16px;
    color: #444;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    input {
      width: 90%;
      padding: 15px;
      border: none;
      background-color: #fff;
      outline: none;
    }

    .fa-search {
      width: 10%;
      padding-right: 8px;
    }
  }

  .list_ul {
    list-style: none;
    // overflow: hidden;
  }

  .list-item {
    position: relative;
    // height: 5rem;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    margin-bottom: 8px;
    border-radius: 5px;
  }

  .list-item[data-type="0"] {
    transform: translate3d(0, 0, 0);
  }

  .list-item[data-type="1"] {
    transform: translate3d(-5rem, 0, 0);
  }

  .list-box {
    padding: 0.5rem;
    background: #fff;
    border-radius: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    justify-content: flex-end;
  }

  .list-item .list-img {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
  }

  .list-item .list-content {
    padding: 0.25rem 0 0.25rem 0.5rem;
    position: relative;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
  }

  .list-item .title {
    display: block;
    overflow: hidden;
    font-size: 0.75rem;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
  }

  .list-item .tips {
    display: block;
    overflow: hidden;
    font-size: 0.6rem;
    color: #999;
    line-height: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
  }

  .list-item .time {
    display: block;
    font-size: 0.6rem;
    position: absolute;
    right: 0;
    top: 0.25rem;
    color: #666;
  }

  .list-item .delete {
    width: 5rem;
    height: 100%;
    // background: #ff4949;
    font-size: 0.85rem;
    color: var(--main);
    text-align: center;
    position: absolute;
    top: 0;
    right: -5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
